import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './Blog.css'
import BlogItem from './subcomponents/BlogItem';

import { useParams } from 'react-router-dom';
import { useLanguage } from "../../LanguageContext";

// Set API URL according to environment
let baseURL;
if (process.env.REACT_APP_NODE_ENV === 'production') {
	baseURL = process.env.REACT_APP_UNISPACE_API_URL;
} else {
	baseURL = process.env.REACT_APP_BASE_API_URL;
}

const Blog = () => {
    const [blogTitle, blogSubtitle] = ['블로그', '우리의 이야기를 담은 space'];
    const [category, setCategory] = useState('전체');
    const [categoryID, setCategoryID] = useState('all');

    const {lang} = useParams();
    const isEnglish = lang == 'en'
    const { setIsEnglish } = useLanguage();

    const [categoryList, setCategoryList] = useState([{
        "id": 'all',
        "name": "전체"
    }])

    const [blogListData, setBlogListData] = useState([]);

    const getCategories = () => {
        axios.get(`${baseURL}/api/contents/categories`)
            .then(response => response.data)
            .then(categoryData => {
                if (categoryData.length > 0) {
                    setCategoryList(categoryList.concat(categoryData))
                }
            })
            .catch(error => console.log(error))
    }

    const getBlogListData = () => {
        const blogContentAPICALL = categoryID === '0'
            ? `${baseURL}/api/contents/blogs`
            : `${baseURL}/api/contents/blogs?category=${categoryID}`;


        axios.get(blogContentAPICALL)
            .then(result => result.data)
            .then(blogListData => {
                setBlogListData(blogListData);
            })
            .catch(error => console.log(error))
    }

    const handleNavChange = (e, id) => {
        setCategory(e.target.outerText);
        setCategoryID(id);
    }

    // to get category list from server
    useEffect(() => {
        getCategories();
    }, [])

    // to update what blogs are shown
    useEffect(() => {
        getBlogListData();
    }, [categoryID])

    return <div className="blogContainer">
        <div className="blogTitle">{isEnglish ? 'Blog': '블로그'}</div>
        <div className="blogSubtitle">{isEnglish ? 'The space containing our stories' : '우리의 이야기를 담은 space'}</div>
        <div className="blogContent">
            <div className="blogNavbar">
                <ul>
                    {categoryList.map((item) => {
                        return (<li
                            key={item.id}
                            className={category === item.name ? 'active' : ''}
                            onClick={e => { handleNavChange(e, item.id) }}> {item.name} </li>);
                    })}

                </ul>
            </div>
            <div className="blogItemContainer">
                {blogListData.map((item) => {
                    console.log(item);
                    console.log(item.id)
                    return (<BlogItem
                        key={item.id}
                        id={item.id}
                        category={item.category?.name}
                        image={item.image}
                        title={item.title}
                    />)
                })}
            </div>
        </div>
    </div>
}

export default Blog;